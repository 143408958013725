<template lang="pug">
  #app
    bk-header(v-if="$route.name !== 'home' && $route.name !== 'catalogoListPublic'")
    #main-cn
      .preloader(v-if="$store.state.pending")
        p #[translate loading]...
      transition(name="animate-css" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in")
        router-view
    bk-footer(v-if="currentUser && $route.name !== 'home' && $route.name !== 'catalogoListPublic'")
    span(class="hidden") #[translate xxx#/]
    #app-bg-hit
</template>
<script>
import BkHeader from '@/components/common/Header.vue'
import BkFooter from '@/components/common/Footer.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'app',
  data () {
    return {
    }
  },
  components: { BkFooter, BkHeader },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ]),
    currentUser() {
      return this.$store.getters?.user
    }
  },
  created () {
  },
  mounted () {
    // Se ejecuta cuando se monta el componente
    // console.log('-->' + this.$store.state.isLoggedIn)
  }
}
</script>
<style lang="scss">
  @import "./assets/sass/style.scss";
  #app{
    position: relative;

    & > #app-bg-hit{
      opacity: 0;
      @include transition();
      &.show{
        position: absolute;
        top: 0;
        bottom:0;
        left:0;
        right: 0;
        background-color: rgba(#707070, .5);
        z-index: 11;
        opacity: 1;
      }
    }
  }
</style>
